import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTextField,{attrs:{"outlined":"","clearable":"","label":"Message","type":"text","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])]}}])},[_vm._v(" I'm a tooltip ")])]},proxy:true},{key:"append",fn:function(){return [_c(VFadeTransition,{attrs:{"leave-absolute":""}},[(_vm.loading)?_c(VProgressCircular,{attrs:{"size":"24","color":"info","indeterminate":""}}):_c(VImg,{attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}})],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.clickMe}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTarget)+" ")]),_vm._v("Click me ")],1)]},proxy:true}]),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})}
var staticRenderFns = []

export { render, staticRenderFns }